import { type Router } from '../router';

export const loadSentry = async (router: Router) => {
  if (process.env.NODE_ENV === 'production' && window.location.origin !== 'localhost') {
    const { init, tanstackRouterBrowserTracingIntegration, replayIntegration } = await import('@sentry/react');

    init({
      dsn: `https://ff3936f96c96429fb55e1ccc40a59198@o25257.ingest.us.sentry.io/${process.env.SENTRY_PROJECT}`,
      maxBreadcrumbs: 50,
      debug: false,
      integrations: [tanstackRouterBrowserTracingIntegration(router), replayIntegration()],
      tracesSampleRate: 0.2,

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
