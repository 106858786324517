import * as i18n from 'i18next';
import LanguageDetectorModule from 'i18next-browser-languagedetector';
import HttpBackendModule from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const initialize = async () => {
  await i18n
    .use(HttpBackendModule)
    .use(LanguageDetectorModule)
    .use(initReactI18next)
    .init({
      ns: ['general'],
      defaultNS: 'general',
      fallbackLng: 'en',
      debug: false,

      interpolation: {
        escapeValue: false,
      },

      backend: {
        loadPath: () => {
          if (process.env.NODE_ENV === 'development') {
            return '/src/locales/{{lng}}/{{ns}}.json';
          }

          return '/locales/{{lng}}/{{ns}}.json';
        },
      },
    });
};

export default initialize;
