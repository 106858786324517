import { useMemo } from 'react';

import { Box, type BoxProps } from '@mui/material';
import { useRouteContext } from '@tanstack/react-router';
import { hex } from 'color-convert';
import { type BackgroundData } from 'typescript/interfaces/BackgroundData';

import c from './_background.module.scss';

type BgConfig = { gradient: Exclude<BackgroundData['gradient'], string | boolean>; src: string };

const defaults = {
  gradient: {
    color: '#000000',
    angle: 180,
    opacity: 0.7,
  },
  src: 'https://front.travpromobile.com/760/bg.jpg',
};

export const Background = () => {
  const context = useRouteContext({ strict: false });

  const sx = useMemo(() => {
    if (context?.background === undefined) {
      return {};
    }

    const bg = [];

    const gradientData = {
      ...defaults.gradient,
      ...((context?.background?.gradient as BgConfig['gradient']) || {}),
    };

    const style = getComputedStyle(document.documentElement);
    const primaryColor = gradientData.color.startsWith('--')
      ? style.getPropertyValue(gradientData.color) || undefined
      : gradientData.color;

    if (primaryColor !== undefined) {
      const [r, g, b] = hex.rgb(primaryColor);

      const colors = [`rgba(${r}, ${g}, ${b}, 1)`, `rgba(${r}, ${g}, ${b}, ${gradientData.opacity})`];

      bg.push(`linear-gradient(${gradientData.angle}deg, ${colors.join(', ')})`);
    }

    bg.push(context.background.src ? `url(${context.background.src})` : 'none');

    return {
      backgroundImage: bg.join(', '),
    } as BoxProps['sx'];
  }, [context?.background]);

  if (sx === undefined) {
    return null;
  }

  return <Box className={c.wrapper} sx={sx} />;
};
