import { type EndpointKey, Endpoints, type ResponseShapes } from './endpoints';

export interface Init extends Omit<RequestInit, 'method'> {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'OPTIONS';
  params?: URLSearchParams;
}

type InferredType<T extends EndpointKey> = T extends keyof ResponseShapes ? ResponseShapes[T] : unknown;

export interface TypedResponse<T extends EndpointKey> extends Response {
  json(): Promise<InferredType<T>>;
}

const isValidEndpointKey = (input: string): input is EndpointKey => {
  return Object.keys(Endpoints).includes(input);
};

const typedFetch = async <T extends EndpointKey>(input: T, init?: Init): Promise<TypedResponse<T>> => {
  if (isValidEndpointKey(input)) {
    let url = Endpoints[input] as string;

    if (init?.params) {
      url = [Endpoints[input], init.params].join('?');
    }

    return fetch(url, init);
  }

  return fetch(input as string | URL | Request, init);
};

export { typedFetch as fetch };
