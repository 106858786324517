import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: false,
      retry: process.env.NODE_ENV === 'development' ? false : 3,
      staleTime: 1000 * 60 * 5,
    },
    mutations: {
      retry: 3,
    },
  },
});
