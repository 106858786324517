import { type ProgressData } from 'api/types/ProgressData';
import { type RawProgressData } from 'api/types/ProgressData/RawProgressData';

import { convertImgixUrl } from 'helpers/convertImgixUrl';

// NOTE: This is a hack to handle cases where progress is not updated for final pages
export const getAdjustedProgress = (progress: number, totalPages: number): number => {
  if (progress === totalPages - 1) {
    return totalPages;
  }
  return progress;
};

export const parseProgressData = (data: { chapters: RawProgressData[] }, id: string): ProgressData => {
  /**
   * Reducer function to calculate the following values:
   *
   * - TotalChapterCompletion: Total completion rate of all chapters. There is a filter that prevents `specialization` chapters from being included in this calculation.
   * - HighestIndividualChapterCompletion: Highest completion rate of any chapter. This is calculated by taking the highest completion rate of any chapter, regardless of the filter. This is useful as a fallback value for cases where there are no valid progress data chapters (non-specialization) present.
   * - HasNormalChapters: Boolean flag to determine if there are any valid progress data chapters (non-specialization) present.
   */
  const { totalChapterCompletion, highestIndividualChapterCompletion, hasNormalChapters } = data.chapters.reduce(
    (acc, chapter) => {
      const { specialization, total_pages: chapterPages } = chapter;
      const adjustedChapterProgress = getAdjustedProgress(chapter.progress, chapter.total_pages);

      let { totalChapterPages, totalCompletedChapterPages, highestIndividualChapterCompletion } = acc;

      // Compare the current chapter's completion rate with the highest completion rate of any chapter. If the current chapter's completion rate is higher, update the highestIndividualChapterCompletion value.
      highestIndividualChapterCompletion = Math.max(
        highestIndividualChapterCompletion,
        Math.min(100, Math.floor((adjustedChapterProgress / chapterPages) * 100)) ?? 0,
      );

      // In cases where the chapter is a specialization chapter, we only want to update the highestIndividualChapterCompletion value. They should not be included towards the normal chapter completion calculation.
      if (Boolean(specialization) === true) {
        return {
          ...acc,
          highestIndividualChapterCompletion,
        };
      }

      // When the chapter is NOT a specialization chapter, we want to include it in the normal chapter completion calculation. All relevant values should be updated and a new totalChapterCompletion value should be calculated.
      totalChapterPages += chapterPages;
      totalCompletedChapterPages += adjustedChapterProgress;
      const newChapterCompletion = Math.min(100, Math.floor((totalCompletedChapterPages / totalChapterPages) * 100));

      return {
        totalChapterCompletion: newChapterCompletion,
        highestIndividualChapterCompletion,
        totalChapterPages,
        totalCompletedChapterPages,
        hasNormalChapters: true, // Set to true since the current chapter is not a specialization chapter.
      };
    },
    {
      totalChapterCompletion: 0,
      highestIndividualChapterCompletion: 0,
      totalChapterPages: 0,
      totalCompletedChapterPages: 0,
      hasNormalChapters: false,
    },
  );

  const output: ProgressData = {
    id: id.toString(),
    // TODO: What even is this? This is 100% going to lead to bugs as the function transforms the data before calculating the completion rate.
    chapterCompletion: (hasNormalChapters ? totalChapterCompletion : highestIndividualChapterCompletion) ?? 0,
    progress: data.chapters.map((chapter) => {
      const progressPercentage = Math.floor(
        (getAdjustedProgress(chapter.progress, chapter.total_pages) / chapter.total_pages) * 100,
      );

      // TODO: The chapter.last_page is no longer supported in the API, Temporary placeholder fix (so we can easily reimplement it later if needed).
      // Currently used in some places, such as trainingOverviewView where you continue based on this value, but it is never updated.
      // To prevent unfixable page start halfway through the training, temporarily replaced lastPage with the chapter.progress (or 0 for completed), so that you at least always start at the beginning when complete.
      const lastPagePlaceholder = progressPercentage === 100 ? 0 : chapter?.progress;
      return {
        badge: chapter.badge ? convertImgixUrl(chapter.badge) : chapter.badge,
        chapterId: chapter.chapter,
        lastPage: lastPagePlaceholder || 0,
        totalPages: chapter.total_pages,
        progress: getAdjustedProgress(chapter.progress, chapter.total_pages),
        progressPercentage: Math.min(100, progressPercentage),
        isSpecialization: Boolean(chapter.specialization),
        isEssential: Boolean(chapter.essentials_chapter),
      };
    }),
  };

  return output;
};
